import React, { useRef } from "react";
import SEO from "../../components/seo/seo";
import { useStaticQuery, graphql } from "gatsby";
import { PageParams } from "../../../types";
import Img from "gatsby-image";

import "./websites.scss";

import { InView } from "react-intersection-observer";

import Section from "../../components/air-section/air-section";
import BoxList from "../../components/box-list/box-list";
import Button from "../../components/button/button";
import Hero from "../../components/hero/hero";
import HeaderBig from "../../components/header-big/header-big";
import CallFooter from "../../components/call-footer/call-footer";
import StickyContainer from "../../components/sticky/sticky-container";
import PortfolioList from "../../components/portfolio-list/portfolio-list";
import { Portfolio, PortfolioNode } from "../../utils/portfolio";

import SeoIcon from "../../images/pages/websites/seo-01.svg";
import ScrollButton from "../../components/scroll-button/scroll-button";

const StronyInternetowePage = (params: PageParams) => {
  const language = params.pageContext.language;
  const scrollRef = useRef();
  const experienceItems = [
    {
      name: "Spotkanie / Brief / Rozmowa telefoniczna",
      description:
        "Wysłuchamy wszystkich Twoich pomysłów, opowiemy o&nbsp;możliwościach technicznych i&nbsp;najkorzystniejszych rozwiązaniach. Przeanalizujemy  możliwości i&nbsp;zasugerujemy technologię, która najlepiej pasuje do realizacji strony internetowej.",
    },
    {
      name: "Analiza i&nbsp;planowanie działań",
      description:
        "Po zapoznaniu się z&nbsp;oczekiwaniami, przemyślimy rozwiązania dla Twojej strony internetowej, biorąc pod uwagę najlepsze praktyki i&nbsp;optymalny budżet realizacji. Przedstawimy doprecyzowaną ofertę.",
    },
    {
      name: "Umowa",
      description:
        "Przygotujemy prostą umowę, w&nbsp;której podsumujemy wszystkie ustalenia i&nbsp;wymagania.",
    },
    {
      name: "Makiety / Prototyp / Projekt graficzny",
      description:
        "Po zaakceptowaniu warunków umowy, nasi graficy przygotują makiety funkcjonalne i&nbsp;zaprojektują grafikę. Pozwoli to wyobrazić sobie, jak będzie wyglądała nowa strona internetowa. Stworzone przez nas, klikalne prototypy, zobrazują rozmieszczenie treści, układ nawigacji i&nbsp;główne funkcjonalności. W&nbsp;tym etapie, możliwe będą również zmiany ustalonych założeń.",
    },
    {
      name: "Programowanie",
      description:
        "Po zaakceptowaniu projektu graficznego, nasi programiści zadbają o&nbsp;jego wdrożenie, zgodnie ze wszystkimi standardami i&nbsp;prawidłowymi praktykami. Zadbają również o&nbsp;prawidłowe działanie wszystkich funkcjonalności ujętych w&nbsp;realizacji.",
    },
    {
      name: "Testowanie / Instalacja / Wydanie",
      description:
        "Po zaprogramowaniu strony www, zostanie ona zainstalowana na serwerze i&nbsp;przetestowana. Możemy zaproponować testy wewnętrzne, w&nbsp;których wezmą udział przedstawiciele Klienta i&nbsp;naszej firmy, lub testy z&nbsp;udziałem respondentów.",
    },
    {
      name: "Opieka / Administracja / Rozwój",
      description:
        "Po skończenu strony internetowej, nasi klienci mogą liczyć na stałą opiekę, administrację oraz możliwość udoskonalania, jeżeli pojawi się taka potrzeba.",
    },
  ];

  const data = useStaticQuery(
    // yummypix: file(relativePath: { eq: "./strony-internetowe/yummypix-rwd.png" }) {
    graphql`
      {
        project: file(
          relativePath: { eq: "strony-internetowe/yummypix-rwd.png" }
        ) {
          id
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        rwd: file(relativePath: { eq: "strony-internetowe/rwd.png" }) {
          id
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        seo: file(relativePath: { eq: "mac-mock.png" }) {
          id
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        cms: file(
          relativePath: { eq: "strony-internetowe/zarzadzanie-strona-cms.png" }
        ) {
          id
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        portfolio: allFile(
          filter: {
            sourceInstanceName: { eq: "portfolio" }
            extension: { eq: "mdx" }
          }
        ) {
          group(field: relativeDirectory) {
            nodes {
              id
              sourceInstanceName
              relativeDirectory
              relativePath
              childMdx {
                frontmatter {
                  hero_color
                  color
                  section_bg
                  category
                  slug
                  title
                  language
                  description
                  short_description
                  created
                  thumbnail {
                    childImageSharp {
                      fluid(maxWidth: 900, maxHeight: 600) {
                        base64
                        src
                        aspectRatio
                        sizes
                        src
                        srcSet
                      }
                    }
                  }
                  gallery_alt
                  gallery {
                    childImageSharp {
                      fluid(maxWidth: 1600) {
                        base64
                        src
                        aspectRatio
                        src
                        srcSet
                        sizes
                      }
                    }
                  }
                }
                body
              }
            }
          }
        }
      }
    `
  );
  const itemSlugs = ["zaklady-miesne-kozlowski", "hevitech", "yummypix"];
  const items = Portfolio.getNodesByLanguage("pl", data.portfolio.group)
    .filter((node: PortfolioNode) => {
      return itemSlugs.indexOf(node.childMdx.frontmatter.slug) >= 0;
    })
    .map((node: PortfolioNode) => {
      return node.childMdx.frontmatter;
    });

  return (
    <>
      <SEO
        title="Strony internetowe"
        description="Projektujemy, programujemy, tworzymy strony internetowe z CMS: Joomla, Wordpress oraz indywidualne rozwiązania. •Strony internetowe ☎ +48 665 035 451"
        lang={language}
      />
      <Hero>
        <div className="container-xs header-padding">
          <h1>Strony internetowe</h1>
          <div className="header-subline">
            <h2 className="subtitle">
              Projektowanie i&nbsp;tworzenie nowoczesnych stron www
              z&nbsp;pozycjonowaniem SEO oraz intuicyjnym CMS'em
            </h2>
          </div>
          <h4>
            W&nbsp;naszych realizacjech, kliencji docenili: ciekawe projekty,
            funkcjonalne i&nbsp;przyjazne interfejsy (UX i&nbsp;UI), skuteczne
            pozycjonowanie SEO, realizacje w&nbsp;krótkim termine oraz CMS,
            który umożliwia samodzielną edycję i&nbsp;obsługę gotowej strony
            www.
          </h4>
        </div>
        <ScrollButton style={{
          position: "absolute",
          left: "50%",
          bottom: "5%",
          transform: "translateX(-50%)"
        }} to={scrollRef} />
      </Hero>

      <Section className="container" ref={scrollRef}>
        <HeaderBig
          tag="h2"
          span="Co zyskasz"
          headerText="Postaramy się opowiedzieć, dlaczego warto zaprojektować stronę z&nbsp;naszą agencją"
        />
      </Section>

      <Section className="">
        <StickyContainer className="r :a-t :c-6 :c-12:xs :c-12:xxs :w">
          <div data-sticky>
            <Img
              fluid={data.project.childImageSharp.fluid}
              className="image-test"
              alt="Projekt strony internetowej, zaprezentowany na urządzeniach firmy Apple"
              title="Layout strony internetowej"
            />
            <p className="align-center">
              <strong>
                Aby udoskonalić usłygi związane z&nbsp;projektowaniem,
                stworzyliśmy kreatywne studio graficzne Unirama.
              </strong>
            </p>
            <div style={{ textAlign: "center" }}>
              <Button color="primary" type="default" to="/" language="pl">
                Dowiedz się więcej o&nbsp;Unirama Design
              </Button>
            </div>
          </div>
          <div className="container static">
            <HeaderBig
              tag="h2"
              span="01</br>Kreatywność"
              headerText="Niepowtarzalny projekt graficzny"
            />
            <p>
              Doskonale wiemy, że wystarczy kilka sekund, aby wyrobić sobie
              zdanie o&nbsp;firmie lub produkcie. Zakłądamy, że nasi kliencji
              wiedzą o&nbsp;istnieniu tzw.{" "}
              <strong>efektu pierwszego wrażania</strong> i&nbsp;nie trzeba ich
              przekonywać jak duży{" "}
              <strong>wpływ ma on na formuowanie opinii</strong>.
            </p>
            <p>
              Właśnie dla dobrego pierwszego wrażenia, proejktujemy przejrzyste,
              orginalne <strong>layouty stron www</strong>. Budujemy świadomy
              <strong> wizerunek firmy</strong> umożliwiając skuteczne
              pozyskiwanie nowych klientów i&nbsp;osiągnięcie założeń
              biznesowych.
            </p>
            <p>
              Potrafimy tworzyć grafikę tak, żeby wzbudzała wśród użytkowników
              pozytywne odczucia. Doskonalimy swoje umiejętności, korzystamy
              z&nbsp;wielu narzędzi UX i&nbsp;UI, śledzimy ogólnoświatowe trendy
              w&nbsp;projektowaniu i&nbsp;uczestniczymy w&nbsp;wielu warsztatach
              po to, by nasze pomysły były oryginalne, użyteczne
              i&nbsp;funkcjonalne.
            </p>
            <p>
              <strong>
                Projektując stronę w&nbsp;naszej agencji, jesteśmy pewni że:
              </strong>
            </p>
            <ul>
              <li>
                projekt graficzny będzie nowoczesny, stylowy i&nbsp;oryginalny,
              </li>
              <li>strona będzie funkcjonalna i&nbsp;użyteczna</li>
              <li>
                użytkownicy będą zadowoleni z&nbsp;nowego wizerunkiem Twojej
                firmy.
              </li>
            </ul>
          </div>
        </StickyContainer>
      </Section>

      <Section backgroundColor="#fbfbfb">
        <StickyContainer className="r :a-t :c-6 :c-12:xs :c-12:xxs :w">
          <div className="container static">
            <HeaderBig
              tag="h2"
              span="02</br>Pozycjonowanie"
              headerText="Optymalizacja pod wyszukiwanie SEM i&nbsp;SEO."
            />
            <p>
              Skuteczne pozycjonowanie ma na celu uzyskanie jak najlepszych
              pozycji w&nbsp;wynikach wyszukiwania np. w&nbsp;wyszukiwarce
              Google.
            </p>
            <p>
              Dzięki prawidło wykonanej stronie, zoptymalizowanej pod
              pozycjonowanie, zwiększamy ruch użytkowników, tym samym większa
              ilość internautów ma możliwość poznania Twojej marki, oferty lub
              produktów.
            </p>
            <p>
              Zoptymalizowane przez nas strony, osiągają bardzo dobre wyniki
              w&nbsp;audytach Google oraz pozwalają zrealizować cele
              marketingowe.
            </p>
            <p>
              <strong>Przygotowując optymalizację SEO:</strong>
            </p>
            <ul>
              <li>
                przygotujemy raporty potwierdzające wyniki oraz wprowadzone
                zmiany,
              </li>
              <li>
                wykonamy audyt jakości prezentowanych treści, w&nbsp;tym równieź
                optymalizację grafiki,
              </li>
              <li>
                kożystamy z&nbsp;najlepszych praktyk, instalujemy m.in.: HTTPS
                i&nbsp;HTTP2, wprowadzamy dane strukturalne.
              </li>
            </ul>
          </div>
          <div data-sticky className="align-center">
            <img
              src={SeoIcon}
              alt="Wykres seo stron internetowych"
              title="Grafika Seo dla stron internetowych"
            />
          </div>
        </StickyContainer>
      </Section>

      <Section>
        <StickyContainer className="r :a-t :c-6 :c-12:xs :c-12:xxs :w">
          <div data-sticky className="">
            <Img
              fluid={data.rwd.childImageSharp.fluid}
              className="image-test"
              alt="Grafika przedstawia stronę internetową wyświetloną na urządzeniu Apple iPad"
              title="Mockup strony na tablecie"
            />
          </div>
          <div className="container static">
            <HeaderBig
              tag="h2"
              span="03</br>Dostępność"
              headerText="Technologia RWD i&nbsp;WCAG"
            />
            <p>
              Wszystkie wykonane przez nas{" "}
              <strong>
                strony internetowe, automatycznie dostosowują się do rozmiaru
                urządzenia
              </strong>{" "}
              na których są wyświetlane. Dzięki wykorzystaniu{" "}
              <strong>techniki RWD</strong> układ przygotowanej strony, wygląda
              dobrze na tabletach, smartfonach i&nbsp;wszystkich rodzajach
              przeglądarek.
            </p>
            <p>
              Zwracamy szczególną uwagę na intuicyjną obsługę i&nbsp;nawigację
              strony, na urządzeniach mobilnych gdzie mała wielkość wyświetlacza
              wprowadza różnego rodzaju ograniczenia funkcjonalne
              i&nbsp;użytkowe.
            </p>
            <p>
              Możemy zagwarantować{" "}
              <strong>dostępność strony dla osób niepełnosprawnych</strong>
              wprowadzając standard Web Content Accessibility Guidelines (
              <strong>WCAG</strong>).
            </p>
            <p>
              <strong>Wykonane przez nas strony są:</strong>
            </p>
            <ul>
              <li>
                Responsive Web Design (RWD) - dostępne na telefon, tablet,
                laptop i&nbsp;komputer)
              </li>
              <li>przystosowane dla osób niepełnosprawnych WCAG</li>
              <li>intuicyjne i&nbsp;funkcjonalne</li>
            </ul>
          </div>
        </StickyContainer>
      </Section>

      <Section backgroundColor="">
        <StickyContainer className="r :a-t :c-6 :c-12:xs :c-12:xxs :w">
          <div className="container static">
            <HeaderBig
              tag="h2"
              span="04</br>System&nbsp;CMS"
              headerText="Intuicyjna administracja w&nbsp;systemach zarządzania treścią"
            />
            <p>
              Dostosujemy system zarządzania stroną do Twoich potrzeb. Mamy
              doświadczenie w&nbsp;tworzeniu stron internetowych opartych
              o&nbsp;najpopularniejsze systemy do zarządzania treścią (
              <strong>Word Press, Joomla, Drupal</strong>) oraz stworzyliśmy
              własny autorski system <strong>Fennec CMS</strong>. Staramy się
              aby użytkownik mógł samodzielnie zarządzać gotowym produktem, bez
              ponoszenia dodatkowych kosztów.
            </p>
            <p>
              Dzięki doświadczeniu, doradzimy najlepsze rozwiązanie spełniające
              wszystkie potrzeby! znajomość popularnych systemów CSM autorski
              system Fennec CMS samodzielna obsługa strony, bez znajomości
              zagadnień programistycznych.
            </p>
          </div>
          <div data-sticky className="">
            <Img
              fluid={data.cms.childImageSharp.fluid}
              className="image-test"
              alt="Grafika przedstawia screenshot panelu administracyjnego FennecCMS umożliwiającego zarządzanie stroną internetową"
              title="Widok systemu do zarządzania stroną internetową"
            />
            <div className="align-center">
              <Button color="primary" type="default" to="/" language="pl">
                Dowiedz się więcej o&nbsp;Fennec CMS
              </Button>
            </div>
          </div>
        </StickyContainer>
      </Section>

      {/* -----------------------------------------` */}
      <Section>
        <StickyContainer className="r :a-t :c-6 :c-12:xs :c-12:xxs :w">
          <div data-sticky className="">
            <Img
              fluid={data.rwd.childImageSharp.fluid}
              className="image-test"
              alt="Grafika przedstawia stronę internetową wyświetloną na urządzeniu Apple iPad"
              title="Mockup strony na tablecie"
            />
          </div>
          <div className="container static">
            <HeaderBig
              tag="h2"
              span="05</br>Administracja"
              headerText="Pełna opieka po wdrożeniowa"
            />
            <p>
              Zdajemy sobie sprawę, że przygotowana strona internetowa będzie
              służyć przez kilka lat. W&nbsp;tym okresie wspieramy naszych
              klientów w hostowaniu, zakupie domeny, konfiguracji
              i&nbsp;administracji serwerem.
            </p>
            <p>
              Jesteśmy również otwarci na wprowadzanie zmian, dodawanie
              kolejnych funkcjonalności i&nbsp;rozwój strony internetowej - pod
              warunkiem, że klient nie będzie chciał samodzielnie zarządzać
              swoją stroną.
            </p>
          </div>
        </StickyContainer>
      </Section>

      {/* -----------------------------------------` */}
      <Section className="container align-center">
        <Button color="primary" type="inversed" to="/kontakt" language="pl">
          Darmowa wycena strony internetowej
        </Button>
      </Section>

      <Section className="container">
        <HeaderBig
          tag="h2"
          span="Strony internetowe"
          headerText="Kilka naszych realizacji"
        />
        <PortfolioList items={items}></PortfolioList>
        <div className="align-center">
          <Button
            color="primary"
            type="inversed"
            to="/realizacje"
            language="pl"
          >
            Zobacz wszystkie realizacje
          </Button>
        </div>
      </Section>

      <Section backgroundColor="#000" className="container">
        <HeaderBig
          tag="h2"
          span="Profity stron</br>internetowych"
          spanColor="#484848"
          headerText="Dlaczego warto przygotować profesjonalną stronę internetową"
          color="#50817b"
        />
        <BoxList
          boxItems={[
            {
              title: `Budowa wizerunku i&nbsp;wiarygodności`,
              span: "01",
              description:
                "Strona internetowa zwiększa wiarygodność firmy, odpowiada za pierwsze wrażenie i&nbsp;kreację wizerunku w&nbsp;oczach konsumenta.",
            },
            {
              title: `Dotarcie do nowych klientów`,
              span: "02",
              description:
                "Zdecydowanie zwiększa prawdopodobieństwo pozyskania nowych klientów. Szczególnie w&nbsp;przypadku stron zoptymalizowanych pod kątem wyszukiwania SEO.",
            },
            {
              title: `Promocja oferty 24h na dobę`,
              span: "03",
              description:
                "Strona internetowa jest dostępna przez całą dobę i jest jedną z najtańszych form reklamy. Dobrze przygotowana strona, może być reklamą przez kilka lat, nie generując dodatkowych kosztów.",
            },
          ]}
        />
      </Section>

      <Section>
        <StickyContainer className="r :a-t :c-6 :c-12:xs :c-12:xxs :w">
          <div data-sticky className="container">
            <HeaderBig
              tag="h2"
              span="Etapy współpracy"
              headerText="Ralizację strony internetowej, przeprowadzimy w&nbsp;kilku prostych krokach"
            />
          </div>
          <ul className="container experience count">
            {experienceItems.map((item, index) => {
              return (
                <InView key={index} rootMargin="-49% 0px -49% 0px">
                  {({ inView, ref, entry }) => (
                    <li ref={ref} className={inView ? "active" : ""}>
                      <div>
                        <h3
                          dangerouslySetInnerHTML={{ __html: item.name }}
                        ></h3>
                        <p
                          dangerouslySetInnerHTML={
                            item.description && { __html: item.description }
                          }
                        ></p>
                      </div>
                    </li>
                  )}
                </InView>
              );
            })}
          </ul>
        </StickyContainer>
      </Section>
      <Section className="container align-center">
        <Button color="primary" type="default" to="/oferta" language="pl">
          Wróć na stronę oferty
        </Button>
      </Section>

      <CallFooter />
    </>
  );
};

export default StronyInternetowePage;
